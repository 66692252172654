import { APPLICATION_FORM_COUNTRIES } from "@/rbac/links.js";
import { SORT_APPLICATION_FORM_COUNTRY } from "@/rbac/permissions.js";

function props(route) {
  let orderByDefault = route.query.order_by_order || "asc";

  if (
    route.query.order_by_id ||
    route.query.order_by_name ||
    route.query.order_by_is_visible_name
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 20,

    id: parseInt(route.query.id) || null,
    order_by_id: route.query.order_by_id || null,

    name: route.query.name || null,
    order_by_name: route.query.order_by_name || null,

    is_visible: route.query.is_visible
      ? parseInt(route.query.is_visible)
      : null,
    order_by_is_visible_name: route.query.order_by_is_visible_name || null,

    order: parseInt(route.query.order) || null,
    order_by_order: orderByDefault,
  };
}

const applicationFormCountryRoutes = [
  {
    path: "/application-form-countries",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "ApplicationFormCountryIndex",
        meta: {
          title: "Application Form Countries",
          permission: APPLICATION_FORM_COUNTRIES,
        },
        props: (route) => props(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-country-index" */ "@/views/system/application-form-country/Index.vue"
          ),
      },
      {
        path: "sort",
        name: "ApplicationFormCountrySort",
        meta: {
          title: "Sort Application Form Countries",
          permission: SORT_APPLICATION_FORM_COUNTRY,
        },
        component: () =>
          import(
            /* webpackChunkName: "application-form-country-sort" */ "@/views/system/application-form-country/Sort.vue"
          ),
      },
    ],
  },
];

export default applicationFormCountryRoutes;
